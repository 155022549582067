import VueJwtDecode from "vue-jwt-decode";

const getToken = () => {
  return localStorage.getItem("app_token");
};

export const getCompanyId = () => {
  const token = getToken();
  const tokenDetails = VueJwtDecode.decode(token);
  return tokenDetails.CompanyId;
};

export const getRole = () => {
  const token = getToken();
  const tokenDetails = VueJwtDecode.decode(token);
  return tokenDetails.role;
};

export const getTokenExpireDate = () => {
  const token = getToken();
  const tokenDetails = VueJwtDecode.decode(token);
  return tokenDetails.exp;
};

export const userId = () => {
  const token = getToken();
  const tokenDetails = VueJwtDecode.decode(token);
  return tokenDetails.nameid;
};

export const companyName = () => {
  const token = getToken();
  const tokenDetails = VueJwtDecode.decode(token);
  return tokenDetails.CompanyName;
};

export const getApiUrl = () => {
  const token = getToken();
  const tokenDetails = VueJwtDecode.decode(token);
  return tokenDetails.Url;
};
