const state = {
  status: false,
};

const mutations = {
  setLoading(state, status) {
    state.status = status;
  },
};
const actions = {
  setLoading({ commit }, status) {
    commit("setLoading", status);
  },
};

const getters = {
  loadingStatus() {
    return state.status;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
