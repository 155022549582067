<template>
  <div>
    <v-app>
      <div v-if="loadingStatus" class="loading">
        <div class="loading-animation">
          <v-progress-linear
            color="#1976d2"
            indeterminate
            height="6"
          ></v-progress-linear>
        </div>
      </div>
      <router-view />
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(["loadingStatus"]),
  },
};
</script>
<style lang="scss">
.swal2-container {
  font-family: "Roboto", sans-serif;
}
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  .loading-animation {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    text-align: center;
  }
}
</style>
